<template>
  <CommunityGroupsWithSearchResults
    :breadcrumb-items="breadcrumbItems"
    :props-data-to-sections="propsDataToSections"
    :groups-arr-to-rendering="filteredListOfCollections"
    v-bind="filteredPropsDataForSearching"
    :props-data-for-searching="filteredPropsDataForSearching"
    @onIntersect="onIntersect"
    @manageActions="manageActions" />
</template>
<script>
import CommunityGroupsWithSearchResults from '@/components/CommunityCollections/CommunityGroupsWithSearchResults';
import { getFullLinkForWorkspaces } from '@/utils';
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import {
  SEARCH_COLLECTIONS_V3,
  SEARCH_PRODUCTS_V3,
  SEARCH_FOLLOWED_PAGES,
} from '@/constants/searching/searchingAsyncV2';
import SearchCollectionsAndProducts
  from '@/services/graphql/searching/searchCollectionsAndProducts';
import SearchCollections from '@/services/graphql/searching/searchCollections';
import SearchProducts from '@/services/graphql/searching/searchProducts';
import SearchWorkspacePages from '@/services/graphql/searching/searchWorkspacePages';
import ManageCollectionsActions from '@/mixins/ManageCollectionsActions';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import { findAndReplace } from '@/utils/replaceHelper';
import {
  last, uniqBy,
} from 'lodash';
export default {
  name: 'SubscriptionCommunityPage',
  components: {
    CommunityGroupsWithSearchResults,
  },
  mixins: [
    ToggleViewCondition,
    ManageCollectionsActions,
  ],
  props: {
    filteredListOfCollections: {
      type: Array,
      default: () => [],
    },
    expandedCollections: {
      type: Object,
      default: () => {
      },
    },
    propsDataForSearching: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      nextTokenToSuggestions: null,
    };
  },
  computed: {
    ...mapState({
      itemsFromCollectionSearch: state => state.Collections.itemsFromCollectionSearch,
      collectionSuggestionsList: state => state.Collections.collectionSuggestionsList,
      isWorkspaceOnlySearch: state => state.isWorkspaceOnlySearch,
    }),
    ...mapGetters('FeatureFlags', ['useLazyLoading']),
    filteredPropsDataForSearching() {
      const {
        propsDataToSections: sectionsData = {
        }, propsDataForSearching: data = {
        },
      } = this;

      const res = {
        ...sectionsData,
        ...data,
        asyncProductsApiMethod: {
          ...data.asyncProductsApiMethod,
        },
        asyncCollectionsAndProductsApiMethod: {
          request: SearchCollectionsAndProducts['asyncCommunityCollectionsAndProductsWithPageV3'],
        },
      };

      // used when searched in community with workspace only filter enabled
      if (this.isWorkspaceOnlySearch) {
        res.asyncCollectionsAndProductsApiMethod = {
          request: SearchCollectionsAndProducts['asyncWorkspaceOnlyCollectionsProductsAndPages'],
          initVariables: {
            followedOnly: true,
          },
        };
        res.asyncCollectionsApiMethod = {
          request: SearchCollections['asyncCollectionsV3'],
          nameRequest: SEARCH_COLLECTIONS_V3,
          initVariables: {
            followedOnly: true,
          },
        };
        res.asyncProductsApiMethod = {
          request: SearchProducts['asyncProductsV3'],
          nameRequest: SEARCH_PRODUCTS_V3,
          initVariables: {
            followedOnly: true,
          },
        };
        res.searchWorkspacePages = {
          request: SearchWorkspacePages.searchFollowed,
          nameRequest: SEARCH_FOLLOWED_PAGES,
        };
      }

      return res;
    },
    propsDataToSections() {
      const { propsDataForSearching: data } = this;
      const { searchWorkspacePages } = data || {
      };
      return {
        // to fetch verified pages when flag is on
        asyncWsPagesApiMethod: {
          request: SearchCollectionsAndProducts['asyncCommunityCollectionsWsPages'],
          initVariables: {
            wpSort: {
              createdDate: 'desc',
            },
            collectionSort: {
              rating: 'asc',
            },
          },
        },
        // for general community pages
        asyncCollectionsAndWsPagesApiMethod: {
          request: SearchCollectionsAndProducts['asyncCommunityCollectionsWsPages'],
          initVariables: {
            wpSort: {
              createdDate: 'asc',
            },
            collectionSort: {
              rating: 'asc',
            },
          },
        },
        asyncCollectionsApiMethod: {
          request: this.getCommunityCollections,
        },
        // when search section is expanded
        searchWorkspacePages: {
          ...searchWorkspacePages,
          initVariables: {
            sort: {
              createdDate: 'asc',
            },
          },
        },
      };
    },
    breadcrumbItems() {
      return {
        breadcrumbs: [
          {
            text: 'Home',
            disabled: false,
            to: '/',
          },
          {
            text: 'Community',
            disabled: true,
          },
        ],
        pushBackUrl: getFullLinkForWorkspaces('libraries'),
      };
    },
  },
  async mounted() {
    this.getSuggestions();
    this.onWorkspacePageChange();
  },
  beforeDestroy() {
    this.removeSubscriptions(['workspace_page_subscription']);
  },
  created() {
    this.setFilteredSearchGroups();
  },
  methods: {
    ...mapActions({
      removeSubscriptions: 'ManageSubscriptions/removeSubscriptions',
      onWorkspacePageChange: 'Workspace/onWorkspacePageChange',
      getCollectionSuggestions: 'Collections/getCollectionSuggestions',
      getCommunityCollections: 'Collections/getCommunityCollections',
    }),
    ...mapMutations({
      spinner: 'spinner',
      setItems: 'Collections/setItemsFromCollectionSearch',
      setCollectionSuggestionsList: 'Collections/setCollectionSuggestionsList',
    }),
    setFilteredSearchGroups() {
      const { itemsFromCollectionSearch: item } = this;
      const filteredArr = findAndReplace({
        item,
        searchBy: {
          criteria: 'wsPages',
        },
        replacedItem: {
          title: 'Pages',
          titleIcon: undefined,
        },
      });
      this.setItems(filteredArr);
    },
    getFullLinkForWorkspaces,
    async getSuggestions({ nextToken, limit = 4 } = {
    }) {
      try {
        const data = await this.getCollectionSuggestions({
          limit,
          nextToken,
        });
        const {
          response: { data: list = [], nextToken: nextTokenResp } = {
          },
        } = data || {
        };
        this.setCollectionSuggestionsList(uniqBy([...this.collectionSuggestionsList, ...list], 'id'));
        this.nextTokenToSuggestions = nextTokenResp;
      } catch (e) {
        console.log('err getSuggestions', e);
      }
    },
    async onIntersect({ intersectedId, mappingName } = {
    }) {
      if (mappingName !== 'suggestions') return;
      if (!this.nextTokenToSuggestions) return;
      const { collectionSuggestionsList: list, spinner } = this;
      if (!list) return;
      const { id: lastId } = last(list) || {
      };
      if (!lastId) return;
      if (intersectedId !== lastId) return;

      if (!this.useLazyLoading) spinner(true);
      await this.getSuggestions({
        nextToken: this.nextTokenToSuggestions,
        limit: 12,
      });
      if (!this.useLazyLoading) spinner(false);
    },
  },
};
</script>
